import React from "react";
// import { Link } 				from "gatsby"
import styled from "styled-components";

import Seo from "../components/seo";
import { SubHeader, Body } from "../components/Typography";
import { SiteContent, HalfWidth } from "../components/Layouts";

import { Colors } from "../theme";

// Images
import OrganicCircles from "../images/svgs/thumbsupstudio_organicCircles.svg";

import "../theme/css/animations.css";

const AboutImageWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: black;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${Colors.Black};

  @media only screen and (max-width: 780px) {
    padding-top: 54px;
  }
`;


const HeroWrapper = styled.div`
  display: flex;
  margin: 128px 0;

  @media only screen and (max-width: 780px) {
    min-height: 800px;
    padding-top: 100px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px;
  div {
    margin: 0;
  }

  @media only screen and (max-width: 780px) {
    padding-top: 54px;
  }
`;

const AboutPage = () => (
  <AboutImageWrapper>
    <Seo title="Thumbs Up Studio Portfolio - Web Design & Development" />
    <HeroWrapper>
      <SiteContent wrap>
        <ContentWrapper>
          <SubHeader large>About</SubHeader>
          <Body
            opacity
            bold
            lineHeight={40}
            fontSize={36}
            style={{ paddingBottom: "9px", paddingTop: "9px" }}
            color={Colors.White}
          >
            Finally&#8212;a small agency, <br/>with a big-league finish.
          </Body>
          <Body maxWidth color={Colors.White}>
            Eric Robinson is the owner and operator of Thumbs Up Studio LLC.
            After years of working in the corporate marketing space and
            small-agency environments, he’s decided to carve out a path of his
            own.
            <br />
            <br />
            Working independently has its ups and downs—but with the help of his
            friends and business partners at Magic Pie Copywriting and Michael
            Smeal Studios—Thumbs Up Studio is a full-fledged digital agency.
          </Body>
        </ContentWrapper>
      </SiteContent>
    </HeroWrapper>
  </AboutImageWrapper>
);

export default AboutPage;
